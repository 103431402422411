import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ seo = {} }) => {
  const { strapiGlobal } = useStaticQuery(query)
  const { defaultSeo, siteName, favicon } = strapiGlobal

  // Merge default and page-specific SEO values
  const fullSeo = { ...defaultSeo, ...seo }

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.url) {
      tags.push({
        property: "og:url",
        content: "https://thelitigationfriend.com" + fullSeo.url,
      })
    }

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      )
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      )
    }
    if (fullSeo.shareImage) {
      const imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        fullSeo.shareImage.publicURL
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        },
        { property: "og:image:secure_url", content: imageUrl }
      )
    }
    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      })
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" })

    return tags
  }

  const getStructuredData = () => {
    let imageUrl = ""
    if (fullSeo.shareImage) {
      imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        fullSeo.shareImage.publicURL
    }
    if (fullSeo.article) {
      return {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "@id": fullSeo.url,
        headline: fullSeo.metaTitle,
        image: imageUrl,
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": "https://thelitigationfriend.com" + fullSeo.url,
        },
        datePublished: fullSeo.publishedAt,
        dateModified: fullSeo.publishedAt,
        url: "https://thelitigationfriend.com" + fullSeo.url,
        articleBody: fullSeo.articleBody,
        author: {
          "@type": "Person",
          name: fullSeo.authorName,
        },
        publisher: {
          "@type": "Organization",
          name: "Litigation Friend Ltd",
          logo: {
            "@type": "ImageObject",
            url: "https://thelitigationfriend.com/lf-logo-white.png",
          },
        },
      }
    }
    return {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "@id": fullSeo.url,
      headline: fullSeo.metaTitle,
      image: imageUrl,
      url: "https://thelitigationfriend.com" + fullSeo.url,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": "https://thelitigationfriend.com" + fullSeo.url,
      },
      publisher: {
        "@type": "Organization",
        name: "Litigation Friend Ltd",
        logo: {
          "@type": "ImageObject",
          url: "https://thelitigationfriend.com/lf-logo-white.png",
        },
      },
    }
  }

  const metaTags = getMetaTags()

  return (
    <Helmet
      title={fullSeo.metaTitle}
      titleTemplate={`%s | ${siteName}`}
      link={[
        {
          rel: "icon",
          href: favicon.publicURL,
        },
      ]}
      script={[
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(getStructuredData()),
        },
      ]}
      meta={metaTags}
      htmlAttributes={{ lang: "en" }}
    >
      <script
        type="text/javascript"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      ></script>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query {
    strapiGlobal {
      siteName
      favicon {
        publicURL
      }
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`

import React, { Component } from "react"
const TrustBox = ({ trustBoxRef }) => (
  <div
    ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
    // [ long list of data attributes...]
    className="trustpilot-widget mt-8"
    target="_blank"
    rel="noopener noreferrer"
    data-locale="en-GB"
    data-template-id="5419b6a8b0d04a076446a9ad"
    data-businessunit-id="603e6d256e8e9300015dfbc6"
    data-style-height="24px"
    data-style-width="100%"
    data-theme="dark"
  >
    <a href="https://uk.trustpilot.com/review/thelitigationfriend.com">
      Trustpilot
    </a>
  </div>
)
class TrustBoxContainer extends Component {
  constructor(props) {
    super(props)
    this.trustBoxRef = React.createRef()
  }
  componentDidMount() {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true)
    }
  }
  render() {
    return <TrustBox trustBoxRef={this.trustBoxRef} />
  }
}
export default TrustBoxContainer

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

export default function ContactUsBanner() {
  const data = useStaticQuery(graphql`
    query ContactUsBanner {
      strapiContactUsBanner {
        title
        description
        buttonText
      }
    }
  `)

  return (
    <div className="bg-green-500">
      <div className="container mx-auto px-4 lg:px-12 py-12 lg:px-12 text-white">
        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-6">
            <p className="font-bold text-2xl mb-8">
              {data.strapiContactUsBanner.title}
            </p>
            <p className="text-2xl mb-8">
              {data.strapiContactUsBanner.description}
            </p>
            <Link
              to="/redundancy-contact-us"
              className="inline-block mb-0 w-full sm:w-auto font-bold text-lg text-center border-4 border-white text-white rounded-md py-2 px-12 transition duration-500 ease select-none hover:text-white hover:bg-pink-800 focus:outline-none hover:border-pink-800 focus:shadow-outline self-start"
            >
              {data.strapiContactUsBanner.buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { Link } from "gatsby"
import ContactUsBanner from "./contact-us-banner"
import Navbar from "./navbar"
import Seo from "./seo"
import { FaInstagram, FaTwitter, FaFacebookF, FaLinkedin } from "react-icons/fa"
import TrustBoxContainer from "./trust-pilot"
import CookieConsent from "react-cookie-consent"

export default function Layout({ children, hideContactBar, seo }) {
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept recommended cookies"
        declineButtonText="Proceed with nescessary cookies only"
        enableDeclineButton="true"
        flipButtons="true"
        style={{ background: "white", color: "black", alignItems: "center" }}
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses="border-t-4 border-green-500"
        buttonWrapperClasses="text-center"
        declineButtonStyle={{
          background: "#d3d3d3",
          color: "white",
          fontWeight: "500",
          width: "200px",
        }}
        buttonStyle={{
          background: "#0ac690",
          color: "white",
          fontWeight: "700",
          width: "200px",
        }}
      >
        <span className="text-black">
          We use cookies to improve your experience of our website. View our{" "}
          <Link to="/privacy/" className="text-green-500">
            privacy policy
          </Link>
        </span>
      </CookieConsent>
      <Seo seo={seo} />
      <div className="text-md sm:text-xl">
        <Navbar />
        {children}
        {!hideContactBar && <ContactUsBanner />}
        <div className="bg-black">
          <div className="container px-4 lg:px-12 py-12 mx-auto text-white">
            <p className="mb-4">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/litigation-friend/"
                rel="noopener"
              >
                <FaLinkedin className="text-4xl inline mr-6" />
              </a>
              <a
                target="_blank"
                href="https://twitter.com/LitigationFrie1/"
                rel="noopener"
              >
                <FaTwitter className="text-4xl inline  mr-6" />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/thelitigationfriend/"
                rel="noopener"
              >
                <FaFacebookF className="text-4xl inline" />
              </a>
            </p>
            <p className="mb-4">
              Copyright © 2024 Litigation Friend Ltd. All Rights reserved.
            </p>
            <p>
              <Link to="/">Home</Link> |{" "}
              <a href="https://doihaveaclaim.thelitigationfriend.com/">
                Do I have a Claim
              </a>{" "}
              | <Link to="/terms">Terms and conditions</Link> |{" "}
              <Link to="/privacy">Privacy Policy</Link>
            </p>
            <TrustBoxContainer />
          </div>
        </div>
      </div>
    </>
  )
}
